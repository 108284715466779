import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, HostListener, inject, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommitteeAuditLogComponent } from '@app/modules/collaboration/committees/components/committee-audit-log/committee-audit-log.component';
import { ActivityLogComponent } from '@app/modules/operations/project-definition/components/activity-log/activity-log.component';
import { HealthMetricTimeRangeComponent } from '@app/modules/reports/health-metrics/components/health-metric-time-range/health-metric-time-range.component';
import { ProjectStageInfoPipe } from '@app/shared/pipes/project-stage-info.pipe';
import { MetaService } from '@app/shared/services/meta.service';
import { ProjectService } from '@app/shared/services/project.service';
import { UserService } from '@app/shared/services/user.service';
import { hasHealthMetricDashboard } from '@app/shared/utils/helper';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FavoriteProject, Permission, Project, ProjectSummary, ProjectSummaryCategory } from 'lfx-pcc';
import { orderBy } from 'lodash';
import { TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { filter, map, Observable, shareReplay, tap } from 'rxjs';

import { CreateProjectComponent } from '../create-project/create-project.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-common-header',
  standalone: true,
  imports: [
    CommonModule,
    TreeSelectModule,
    ReactiveFormsModule,
    SkeletonModule,
    TooltipModule,
    RouterModule,
    MessagesModule,
    ActivityLogComponent,
    ButtonModule,
    HealthMetricTimeRangeComponent,
    SidebarModule,
    CommitteeAuditLogComponent,
    ProjectStageInfoPipe
  ],
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit, OnChanges {
  @Input() public projectID: string | null;
  @Input() public currentProjectID: string | null;

  public project$: Observable<Project | null>;
  public project: Project;
  public foundation: ProjectSummary;
  public foundation$: Observable<ProjectSummary[]>;
  public form: FormGroup;
  public favoriteProject$: Observable<FavoriteProject[]>;
  public isFavorite: boolean = false;
  public escalateReviewLink: string = 'mailto:formation@linuxfoundation.org?subject=Status of project';
  public showAuditLog = false;
  public permissions$: Observable<Permission> = new Observable<Permission>();
  public permissions: Permission = {};
  public childProjects: TreeNode<Project | ProjectSummary>[] = [];
  public isHealthMetrics: boolean = false;
  public showHealthTimeRange: boolean = false;
  public pageTitle: string = '';

  // Convert constructor parameters to class properties with private keyword and inject dependencies
  private projectService: ProjectService = inject(ProjectService);
  private userService: UserService = inject(UserService);
  private router: Router = inject(Router);
  private fb: FormBuilder = inject(FormBuilder);
  private dialogService: DialogService = inject(DialogService);
  private metaService: MetaService = inject(MetaService);

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(): void {
    this.pageTitle = this.metaService.title;
    this.showHealthTimeRange = this.isHealthMetrics && window.scrollY >= 110;
  }

  public ngOnInit(): void {
    this.isHealthMetrics = this.router.url.includes('health-metrics');
    this.pageTitle = this.metaService.title;

    this.form = this.fb.group({
      project: ['']
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.router.url.includes('health-metrics') !== this.isHealthMetrics) {
          this.isHealthMetrics = this.router.url.includes('health-metrics');
          this.initializeProject();
        }
      });

    this.form.controls.project.valueChanges.pipe(untilDestroyed(this)).subscribe((project) => {
      this.onChangeProject(project);
    });

    if (this.projectID) {
      this.isHealthMetrics = this.router.url.includes('health-metrics');
      this.initializeProject();
    }
  }

  public ngOnChanges(): void {
    this.pageTitle = this.metaService.title;
    if (this.projectID) {
      this.initializeProject();
    }
  }

  public get hasShowAuditLog(): boolean {
    return this.hasCommitteeAuditLog || this.hasProjectAuditLog;
  }

  public get hasCommitteeAuditLog(): boolean {
    return this.router.url.includes('collaboration/committees');
  }

  public get hasProjectAuditLog(): boolean {
    return this.router.url.includes('operations/project-definition') && this.userService.permissions?.project_audits?.view_all;
  }

  public get canCreateProjects(): boolean {
    return this.permissions?.projects?.create && this.userService.lfStaffUser;
  }

  public toggleAuditLog(): void {
    this.showAuditLog = !this.showAuditLog;
  }

  public onChangeProject(project: TreeNode<Project | ProjectSummary>): void {
    this.isHealthMetrics = this.router.url.includes('health-metrics');
    if (this.isHealthMetrics) {
      this.router.navigate([`/project/${project.key}/reports/health-metrics`], { onSameUrlNavigation: 'reload' });
    } else {
      this.router.navigate([`/project/${project.key}`], { onSameUrlNavigation: 'reload' });
    }
  }

  public onShowProjects(event: any): void {
    setTimeout(() => {
      const overlay = event.overlay as HTMLElement;
      const tree = overlay.querySelector('.p-treeselect-items-wrapper') as HTMLElement;

      if (overlay) {
        // Find child element with class p-highlight
        const highlight = tree.querySelector('.p-highlight') as HTMLElement;
        // Scroll to the highlighted element
        if (highlight) {
          // Scroll to the highlighted element smoothly
          tree.scrollTo({
            top: highlight.offsetTop - tree.offsetTop - 10,
            behavior: 'smooth'
          });
        }
      }
    }, 0);
  }

  public onCreate(): void {
    this.dialogService
      .open(CreateProjectComponent, {
        header: 'Add Project',
        width: '70%',
        data: {
          parent: this.project
        }
      })
      .onClose.subscribe((project) => {
        if (project) {
          this.router.navigate([`/project/${project.ID}`], { onSameUrlNavigation: 'reload' });
        }
      });
  }

  public onToggleFavorite(): void {
    if (!this.isFavorite) {
      this.userService.favoriteProjects.push({
        ID: this.project.ID,
        Name: this.project.Name,
        Slug: this.project.Slug
      });
    } else {
      this.userService.favoriteProjects = this.userService.favoriteProjects.filter((project) => project.ID !== this.project.ID);
    }

    this.userService.updateUserFavoriteProjects();
  }

  private initializeProject(): void {
    this.project$ = this.projectService.currentProject$.pipe(
      tap((project) => {
        if (project) {
          this.permissions$ = this.userService.permissions$.pipe(tap((permissions) => (this.permissions = permissions)));
          this.project = project as Project;
          this.getFoundationProject();
          this.favoriteProject$ = this.userService.favoriteProjects$.pipe(
            map((projects) => projects.filter((project) => project.ID === this.project.ID)),
            tap((projects) => {
              this.isFavorite = projects && projects.length > 0;
            })
          );
        }
      })
    );
  }

  private mapTreeNodes(projects: ProjectSummary[], path: ProjectSummary[]): TreeNode<Project | ProjectSummary>[] {
    if (this.isHealthMetrics) {
      return this.mapHealthMetricProjectsOnly(projects);
    }

    return projects.map((p) => {
      const categories: any[] = [];

      if (p.Categories?.length) {
        p.Categories.forEach((c: ProjectSummaryCategory) => {
          if (c.Name && c.Name.trim() !== '' && c.Name !== 'None' && c.Projects && c.Projects.length > 0) {
            categories.push({
              key: `${c.Name}${p.ID}`,
              label: c.Name,
              type: 'category',
              selectable: false,
              styleClass: 'category-label',
              data: c,
              leaf: c.Projects && c.Projects.length > 0,
              children: c.Projects && c.Projects.length ? this.mapTreeNodes(c.Projects as ProjectSummary[], path || []) : []
            });
          }

          if ((c.Name === 'None' || c.Name.trim() === '') && c.Projects && c.Projects.length > 0) {
            p.Projects = orderBy([...c.Projects, ...((p.Projects as ProjectSummary[]) || [])], ['Name'], ['asc']);
          }
        });
      }

      const node: TreeNode<Project | ProjectSummary> = {
        key: p.ID,
        label: p.Name,
        type: 'project',
        data: p,
        expanded: p.ID === this.projectID ? true : false,
        leaf: p.Projects && p.Projects.length > 0,
        children: p.Projects && p.Projects.length ? [...categories, ...this.mapTreeNodes(p.Projects, path)] : [...categories]
      };

      return node;
    });
  }

  private mapHealthMetricProjectsOnly(projects: ProjectSummary[] | Project[]): TreeNode<Project | ProjectSummary>[] {
    return (projects as ProjectSummary[])
      .filter((p: ProjectSummary) => hasHealthMetricDashboard(p))
      .map((p) => {
        const node: TreeNode<Project | ProjectSummary> = {
          key: p.ID,
          label: p.Name,
          type: 'project',
          data: p,
          expanded: false,
          leaf: (p.Projects && p.Projects.some((p) => hasHealthMetricDashboard(p))) || undefined,
          children:
            p.Projects && p.Projects.some((p) => hasHealthMetricDashboard(p)) ? this.mapHealthMetricProjectsOnly(p.Projects as ProjectSummary[]) : undefined
        };

        return node;
      });
  }

  private getFoundationProject(): void {
    this.childProjects = [];
    let foundation = this.project.ParentHierarchy || this.project;

    while (foundation.ParentHierarchy && foundation.ParentHierarchy.ID && foundation.ParentHierarchy.ID !== environment.linuxFoundationSFID) {
      foundation = foundation.ParentHierarchy;
    }

    let summaryParams: HttpParams = new HttpParams({
      fromObject: { $filter: `id eq ${foundation.ID}`, orderBy: 'name', view: 'pcc', pageSize: '9999' }
    });

    if (this.isHealthMetrics) {
      summaryParams = summaryParams.delete('$filter').append('excludeCategories', true);
    }

    this.foundation$ = this.projectService.getProjectSummary(summaryParams).pipe(
      shareReplay(1),
      tap((projects) => (this.foundation = (projects.find((p) => p.ID === foundation.ID) as ProjectSummary) || this.project)),
      tap((projects) => {
        if (!this.isHealthMetrics) {
          projects.forEach((project) => {
            const pathToCurrentProject = this.findProjectAndPath(project.Projects || [], this.project.ID);
            const categories: any[] = [];

            if (project.Categories?.length > 0 && !this.isHealthMetrics) {
              project.Categories.forEach((c: ProjectSummaryCategory) => {
                if (c.Name && c.Name.trim() !== '' && c.Name !== 'None' && c.Projects && c.Projects.length > 0) {
                  categories.push({
                    key: `${c.Name}${project.ID}`,
                    label: `${c.Name}`,
                    selectable: false,
                    type: 'category',
                    data: c,
                    styleClass: 'category-label',
                    leaf: c.Projects && c.Projects.length > 0,
                    children: c.Projects && c.Projects.length ? this.mapTreeNodes(c.Projects as ProjectSummary[], pathToCurrentProject || []) : []
                  });
                }

                if ((c.Name === 'None' || c.Name.trim() === '') && c.Projects && c.Projects.length > 0) {
                  project.Projects = orderBy([...c.Projects, ...((project.Projects as ProjectSummary[]) || [])], ['Name'], ['asc']);
                }
              });
            }

            if (!this.isHealthMetrics) {
              this.childProjects.push({
                key: project.ID,
                label: project.Name,
                type: 'project',
                data: project,
                expanded: project.ID === foundation.ID || this.projectID === project.ID ? true : false,
                leaf: project.Projects && project.Projects.length > 0,
                children:
                  project.Projects && project.Projects.length
                    ? [...categories, ...this.mapTreeNodes((project.Projects as ProjectSummary[]) || [], pathToCurrentProject || [])]
                    : []
              });
            }
          });
        } else {
          this.childProjects = this.mapHealthMetricProjectsOnly(projects);
        }

        // Move The Linux Foundation to the top
        this.childProjects = this.childProjects.sort((a, b) => {
          if (a?.label === 'The Linux Foundation') {
            return -1;
          } else if (b?.label === 'The Linux Foundation') {
            return 1;
          }

          return 0;
        });

        this.form.controls.project.setValue(
          {
            key: this.project.ID,
            label: this.project.Name,
            icon: 'pi pi-fw pi-inbox',
            type: 'project',
            data: this.project,
            leaf: this.project.Projects && this.project.Projects.length > 0,
            children: []
          },
          { emitEvent: false }
        );
      })
    );
  }

  private findProjectAndPath(projects: ProjectSummary[], currentProjectId: string): ProjectSummary[] | null {
    const path: ProjectSummary[] = [];

    function dfs(projects: ProjectSummary[]): boolean {
      for (const project of projects) {
        path.push(project);

        if (project.ID === currentProjectId) {
          return true; // found the project
        }

        if (project.Projects && dfs(project.Projects)) {
          return true; // found the project in the sub-projects
        }

        // project not found, remove it from path
        path.pop();
      }

      return false; // project not found in this path
    }

    return dfs(projects) ? path : null;
  }
}
