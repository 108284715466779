<div class="navigation" [class.expanded]="sidebarExpanded">
  <div class="expand">
    <button (click)="toggleSidebar()">
      <i class="fa-solid fa-angles-down" [ngClass]="sidebarExpanded ? 'fa-rotate-90' : 'fa-rotate-270'"></i>
    </button>
  </div>
  <div class="search-input pt-2">
    <lfx-global-search></lfx-global-search>
  </div>
  <ng-container>
    <div class="navigation-wrapper">
      <div class="navigation-group" [class.all-projects]="!projectID">
        <div class="navigation-item home" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="current">
          <a [routerLink]="['/project', projectID || linuxFoundationSFID]" class="pl-5">
            <i class="fa-light fa-home"></i>
            <span>Home</span>
          </a>
        </div>
      </div>
      <ng-container *ngIf="projectID">
        <ng-container *ngIf="project$ | async as project">
          <div class="navigation-group" *ngFor="let nav of navItems; index as idx" data-cy="sidenav-group" [ngClass]="nav.active ? 'active' : ''">
            <a class="navigation-group-title" [class.active]="nav.active" (click)="toggleDropdown(nav)" data-cy="sidenav-group-title">
              <i class="fa-solid text-blue-light" [ngClass]="nav.active ? 'fa-angle-down' : 'fa-angle-right'"></i>
              <span>{{ nav.name }}</span>
            </a>
            <div class="wrapper" [ngStyle]="{ maxHeight: getNavGroupMaxHeight(nav.items, !nav.active) }">
              <div
                class="navigation-item"
                *ngFor="let item of nav.items"
                [class.active]="item.active"
                [routerLinkActive]="item.routerLink ? 'active' : ''"
                data-cy="sidenav-group-items">
                <div *ngIf="item.disabled" class="disabled">
                  <i [class]="item.icon"></i>
                  <span>{{ item.name }}</span>
                </div>
                <a
                  *ngIf="item.routerLink && !item.disabled && !item.hidden"
                  [routerLink]="item.routerLink ? item.routerLink : ''"
                  [routerLinkActive]="item.routerLink ? 'active' : ''"
                  [class.active]="item.active"
                  [class.disabled]="item.disabled">
                  <i [class]="item.icon" [class.fas]="item.active"></i>
                  <span>{{ item.name }}</span>
                </a>
                <a
                  *ngIf="item.slug && !item.disabled && !item.hidden"
                  [href]="item.slug"
                  [routerLinkActive]="item.routerLink ? 'active' : ''"
                  [class.active]="item.active"
                  [class.disabled]="item.disabled">
                  <i [class]="item.icon" [class.fas]="item.active"></i>
                  <div class="v1-link">
                    <span>{{ item.name }}</span>
                    <p-tag pTooltip="This service will redirect you to PCC V1, and will soon be migrated.">V1</p-tag>
                  </div>
                </a>
                <a *ngIf="item.url && !item.disabled && !item.hidden" [href]="item.url" [target]="item.target">
                  <i [class]="item.icon" [class.fas]="item.active"></i>
                  <span>{{ item.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <hr />
      <div class="dashboard-navigation" [class.all-projects]="!projectID">
        <div class="navigation-group">
          <div class="navigation-item home" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="current">
            <a routerLink="/all-projects" class="pl-5">
              <i class="fa-light fa-layer-group"></i>
              <span>All Projects</span>
            </a>
          </div>
        </div>

        <ng-container *ngIf="user$ | async; else navigationGroupLoading">
          <ng-container *ngIf="assignedProjects$ | async as assignedProjects; else navigationGroupLoading">
            <div class="navigation-group">
              <a class="navigation-group-title" (click)="toggleGroupHeight('Assigned')">
                <i class="fa-solid text-blue-light" [ngClass]="assignedProjectsCollapsed ? 'fa-angle-right' : 'fa-angle-down'"></i>
                <span class="text-sm">My Projects</span>
              </a>

              <div class="no-projects" *ngIf="!assignedProjects?.length && !assignedProjectsCollapsed">
                <span>No Assigned Projects</span>
              </div>

              <div
                class="navigation-item-container"
                [class.active]="assignedProjectsShowMore"
                *ngIf="assignedProjects && assignedProjects.length"
                [ngStyle]="{ maxHeight: getGroupMaxHeight(assignedProjects, assignedProjectsShowMore, assignedProjectsCollapsed) }">
                <lfx-navigation-item *ngFor="let item of assignedProjects" [item]="item"></lfx-navigation-item>
              </div>
              <div class="show-more" *ngIf="assignedProjects.length > 3 && !assignedProjectsCollapsed">
                <a (click)="toggleProjectHeight('Assigned Projects')">
                  <span>View {{ assignedProjectsShowMore ? 'Less' : 'More' }}</span>
                  <i class="fa-solid text-blue-light" [ngClass]="assignedProjectsShowMore ? 'fa-angle-up' : 'fa-angle-down'"></i>
                </a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="favoriteProjects$ | async as favoriteProjects; else navigationGroupLoading">
            <div class="navigation-group">
              <a class="navigation-group-title" (click)="toggleGroupHeight('Bookmarks')">
                <i class="fa-solid text-blue-light" [ngClass]="favoriteProjectsCollapsed ? 'fa-angle-right' : 'fa-angle-down'"></i>
                <span class="text-sm">Bookmarks</span>
              </a>

              <div class="no-projects" *ngIf="!favoriteProjects?.length && !favoriteProjectsCollapsed">
                <span>No Bookmarked Projects</span>
              </div>

              <div
                class="navigation-item-container"
                [class.active]="favoriteProjectsShowMore"
                *ngIf="favoriteProjects && favoriteProjects.length"
                [ngStyle]="{ maxHeight: getGroupMaxHeight(favoriteProjects, favoriteProjectsShowMore, favoriteProjectsCollapsed) }">
                <lfx-navigation-item *ngFor="let item of favoriteProjects" [item]="item" [showFavorite]="true"></lfx-navigation-item>
              </div>
              <div class="show-more" *ngIf="favoriteProjects.length > 3 && !favoriteProjectsCollapsed">
                <a (click)="toggleProjectHeight('Favorite Projects')">
                  <span>View {{ favoriteProjectsShowMore ? 'Less' : 'More' }}</span>
                  <i class="fa-solid text-blue-light" [ngClass]="favoriteProjectsShowMore ? 'fa-angle-up' : 'fa-angle-down'"></i>
                </a>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <!-- Global Menu-->
        <div class="navigation-group" *ngIf="permissions$ | async as permissions">
          <a
            class="navigation-group-title"
            [class.active]="!globalCollapsed"
            (click)="toggleGroupHeight('Global')"
            *ngIf="
              (permissions.surveys_schedule?.view && permissions.project_committee_surveys.view_all) ||
              permissions.project_committee_audit_user_global?.view_all
            ">
            <i class="fa-solid text-blue-light" [ngClass]="globalCollapsed ? 'fa-angle-right' : 'fa-angle-down'"></i>
            <span class="text-sm">Global</span>
          </a>
          <div>
            <div *ngIf="!globalCollapsed" class="wrapper">
              <div *ngIf="permissions.surveys_schedule?.view && permissions.project_committee_surveys.view_all" class="navigation-item">
                <a routerLink="/global-surveys" routerLinkActive="active">
                  <i class="fa-light fa-square-poll-vertical"></i>
                  <span>Surveys</span>
                </a>
              </div>
              <div *ngIf="permissions.project_committee_audit_user_global?.view_all" class="navigation-item">
                <a routerLink="/global-committee-audit" routerLinkActive="active">
                  <i class="fa-users-class far"></i>
                  <span>Committee Audit</span>
                </a>
              </div>
              <div *ngIf="permissions.project_summary.view_all" class="navigation-item">
                <a routerLink="/project-formation" routerLinkActive="active">
                  <i class="fa-layer-group far"></i>
                  <span>Project Formation</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Global Menu-->

        <div class="navigation-group">
          <div class="navigation-item home" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="current">
            <a class="pl-5">
              <i class="fa-duotone fa-arrows-rotate"></i>
              <button routerLink="/changelog" data-badge-changelog>Changelog</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #navigationGroupLoading>
  <p-skeleton width="100%" height="30px" borderRadius="0"></p-skeleton>
</ng-template>
